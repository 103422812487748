<template>
  <div class="card p-shadow-6">
    <div class="p-grid p-col-12 p-lg-12">
      <h1>Resumo das Competências</h1>
      <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
      <Divider />
      <DataTable
        :value="resume"
        :filters.sync="filters"
        filterDisplay="row"
        :loading="loading"
        :paginator="false"
        class="p-datatable-sm"
        :sortOrder="-1"
        sortField="toFillUserCompetency"
        responsiveLayout="scroll"
      >
        <template #empty> Nada a mostrar. </template>
        <template #loading> A carregar. Por favor Aguarde... </template>
        <Column
          field="username"
          header="Duração"
          sortable
          filterField="username"
        >
          <template #body="slotProps">
            {{ slotProps.data.username }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="fillUserCompetency"
          header="Preenchidas"
          sortable
          filterField="fillUserCompetency"
        >
          <template #body="slotProps">
            {{ slotProps.data.fillUserCompetency }}
          </template>
        </Column>
        <Column
          field="toFillUserCompetency"
          header="Por Preencher"
          sortable
          filterField="toFillUserCompetency"
        >
          <template #body="slotProps">
            {{ slotProps.data.toFillUserCompetency }}
          </template>
        </Column>
        <Column
          :styles="{ width: '10px' }"
          :bodyStyle="{ 'text-align': 'left' }"
          :headerStyle="{ width: '50px' }"
        >
          <template #body="slotProps">
            <Button
              v-tooltip.left="'Ver Competências do utilizador'"
              icon="pi pi-eye"
              class="p-button-rounded p-button-text p-button-plain"
              @click="
                viewUserCompetency(
                  slotProps.data.userId,
                  slotProps.data.username
                )
              "
            />
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import competencyService from "../services/competency.service";
import { FilterMatchMode } from "primevue/api/";

export default {
  name: "CompetencyProfileResume",

  data() {
    return {
      loading: true,
      filters: {
        username: { value: null, matchMode: FilterMatchMode.CONTAINS }
      },
      resume: []
    };
  },
  async created() {
    await this.countAllCompetencyToFill();
    this.loading = false;
  },
  methods: {
    countAllCompetencyToFill() {
      return competencyService
        .countAllCompetencyToFill()
        .then(response => (this.resume = response));
    },
    viewUserCompetency(userId, username) {
      let route = this.$router.resolve({
        path: `/competency-profile-user/${userId}/${username}`
      });
      window.open(route.href, "_blank");
    }
  }
};
</script>
